import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ChargingStation, ChargingStationConfigurationMode } from '../../types/ChargingStation';
import { ActionResponse, ChargingStationDataResult } from '../../types/DataResult';
import { RESTServerRoute } from '../../types/Server';
import { buildUrl } from '../../utils/url/url.util';

@Injectable()
export class ChargingStationApiService {
  public constructor(private httpClient: HttpClient) {}

  public getChargingStations(params: {
    Issuer?: boolean;
    WithSiteArea?: boolean;
    WithSite?: boolean;
    WithUser?: boolean;
    Limit?: number;
    SortFields?: string;
  }): Observable<ChargingStationDataResult> {
    return this.httpClient.get<ChargingStationDataResult>(RESTServerRoute.REST_CHARGING_STATIONS, {
      params,
    });
  }

  public updateChargingStation(chargingStation: ChargingStation): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_UPDATE_PARAMETERS, {
      id: chargingStation.id,
    });
    return this.httpClient.put<ActionResponse>(url, chargingStation);
  }

  public switchChargingStationToConfigMode(
    id: string,
    mode: ChargingStationConfigurationMode,
  ): Observable<ActionResponse> {
    const url = buildUrl(RESTServerRoute.REST_CHARGING_STATIONS_CONFIGURATION_MODE, {
      id: id,
      mode: mode,
    });
    return this.httpClient.put<ActionResponse>(url, {});
  }
}
