import { Injectable } from '@angular/core';
import { mergeMap, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';
import { ActionResponse } from '../../types/DataResult';
import { ChargingStation, ChargingStationConfigurationMode } from '../../types/ChargingStation';
import { HTTPError } from '../../types/HTTPError';
import { ChargingStationApiService } from '../../api/charging-station/charging-station.api.service';
import {
  ChargingPointNotValidError,
  ChargingStationNotFoundError,
  ChargingStationNotUpdatedError,
  FeatureNotSupportedError,
  ThreePhaseChargerOnSinglePhaseSiteAreaError,
} from './errors/errors';

@Injectable()
export class ChargingStationRepository {
  public constructor(private readonly chargingStationApi: ChargingStationApiService) {}

  public getAllChargingStations(
    params: {
      Issuer?: boolean;
      WithSiteArea?: boolean;
      WithSite?: boolean;
      WithUser?: boolean;
      Limit?: number;
      SortFields?: string;
      Skip?: number;
    } = {},
  ): Observable<ChargingStation[]> {
    params.Limit = params.Limit ?? 50;
    params.SortFields = params.SortFields ?? 'id';
    params.Skip = params.Skip ?? 0;

    return this.chargingStationApi.getChargingStations(params).pipe(
      mergeMap((result) => {
        if (result.result.length + params.Skip >= result.count) {
          return of(result.result);
        }

        return this.getAllChargingStations({ ...params, Skip: result.result.length + params.Skip }).pipe(
          map((chainResult) => {
            return [...result.result, ...chainResult];
          }),
        );
      }),
    );
  }

  public updateChargingStation(chargingStation: ChargingStation): Observable<ActionResponse> {
    return this.chargingStationApi.updateChargingStation(chargingStation).pipe(
      catchError((error) => {
        switch (error.status) {
          case StatusCodes.NOT_FOUND:
            throw new ChargingStationNotFoundError();
          case HTTPError.THREE_PHASE_CHARGER_ON_SINGLE_PHASE_SITE_AREA:
            throw new ThreePhaseChargerOnSinglePhaseSiteAreaError();
          case HTTPError.CHARGE_POINT_NOT_VALID:
            throw new ChargingPointNotValidError();
          case HTTPError.FEATURE_NOT_SUPPORTED_ERROR:
            throw new FeatureNotSupportedError();
          default:
            throw new ChargingStationNotUpdatedError();
        }
      }),
    );
  }

  public switchChargingStationToConfigMode(
    id: string,
    mode: ChargingStationConfigurationMode,
  ): Observable<ActionResponse> {
    return this.chargingStationApi.switchChargingStationToConfigMode(id, mode).pipe(
      catchError((ex) => {
        throw new ChargingStationNotUpdatedError(ex);
      }),
    );
  }
}
